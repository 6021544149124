import React from "react";
import theme from "theme";
import { Theme, Text, Link, Image, LinkBox, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"works/mini-projects"} />
		<Helmet>
			<title>
				Мини-проекты | Дизайнер Артём Жигалин
			</title>
			<meta name={"description"} content={"Игры и приложения, в которых я принимал участие"} />
			<meta property={"og:title"} content={"Мини-проекты | Дизайнер Артём Жигалин"} />
			<meta property={"og:description"} content={"Игры и приложения, в которых я принимал участие"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/az-logo-512.png?v=2022-06-20T14:58:50.557Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/az-logo-32.png?v=2022-06-18T12:04:38.725Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/az-logo-152.png?v=2022-06-18T12:04:53.625Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/az-logo-152.png?v=2022-06-18T12:04:53.625Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/az-logo-152.png?v=2022-06-18T12:04:53.625Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/az-logo-152.png?v=2022-06-18T12:04:53.625Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/az-logo-270.png?v=2022-06-18T12:05:00.760Z"} />
		</Helmet>
		<Box
			min-width="100px"
			border-width="22px"
			border-style="solid"
			border-color="--color-lightD2"
			display="flex"
			flex-direction="column"
			padding="12px 24px 24px 24px"
			md-border-width="0px"
		>
			<Components.Header mix-blend-mode="difference" sm-padding="0px 0px 0px 0px">
				<Override slot="link" color="--darkL2" />
				<Override slot="link1" color="--darkL2" />
				<Override slot="link2" color="--darkL2" />
			</Components.Header>
			<Section quarkly-title="Cover" margin="50px 0 0px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="center"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Box
					min-width="100px"
					min-height="100px"
					grid-column="2/3"
					display="flex"
					flex-direction="row"
					flex-wrap="wrap"
				>
					<Text
						margin="0px 0px 24px 0px"
						color="--darkL2"
						font="--headline2"
						width="100%"
						sm-font="--headline3"
					>
						Discount Hunter
					</Text>
					<Text
						margin="0px 0px 24px 0px"
						color="--darkL2"
						font="--lead28"
						max-width="760px"
						sm-font="--lead21"
						width="100%"
					>
						A mini game we made for uKit marketing purposes. The user runs around different cities and collects crystals while shooting off snowmen. The more crystals he collects, the more discount on the tariff he will get at the end.
					</Text>
					<Text
						margin="0px 0px 24px 0px"
						color="--darkL2"
						font="--lead28"
						max-width="760px"
						sm-font="--lead21"
						width="100%"
					>
						The game is made on the{" "}
						<Link
							href="http://phaser.io"
							target="_blank"
							color="--darkL2"
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							phaser.io
						</Link>
						{" "}engine.
					</Text>
					<Box
						min-width="100px"
						min-height="100px"
						width="66%"
						padding="0px 16px 0px 0px"
						sm-width="100%"
					>
						<LinkBox align-items="flex-start" flex-direction="row" justify-content="flex-start" margin="0px 0px 6px 0px">
							<Image
								src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/uKit-favicon.png?v=2022-06-07T08:26:23.490Z"
								display="block"
								width="24px"
								height="24px"
								margin="0px 6px 0px 0px"
								srcSet="https://smartuploads.quarkly.io/622f01726ba4ec00186af2ff/images/uKit-favicon.png?v=2022-06-07T08%3A26%3A23.490Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/622f01726ba4ec00186af2ff/images/uKit-favicon.png?v=2022-06-07T08%3A26%3A23.490Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/622f01726ba4ec00186af2ff/images/uKit-favicon.png?v=2022-06-07T08%3A26%3A23.490Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/622f01726ba4ec00186af2ff/images/uKit-favicon.png?v=2022-06-07T08%3A26%3A23.490Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/622f01726ba4ec00186af2ff/images/uKit-favicon.png?v=2022-06-07T08%3A26%3A23.490Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/622f01726ba4ec00186af2ff/images/uKit-favicon.png?v=2022-06-07T08%3A26%3A23.490Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/622f01726ba4ec00186af2ff/images/uKit-favicon.png?v=2022-06-07T08%3A26%3A23.490Z&quality=85&w=3200 3200w"
								sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,(max-width: 1536px) 100vw,100vw"
							/>
							<Link
								href="http://nygame.ukit.space/"
								color="--primary"
								display="inline-block"
								target="_blank"
								font="--base"
								text-decoration-line="initial"
								hover-color="#0b85db"
							>
								nygame.ukit.space
							</Link>
						</LinkBox>
						<Text margin="0px 0px 0px 0px" font="--base" color="--darkL2">
							Completion date: December 22, 2016
						</Text>
						<Text margin="0px 0px 0px 0px" font="--base" color="--darkL2">
							Made in 15 days
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px" width="33%" sm-width="100%">
						<Text margin="0px 0px 0px 0px" font="--base" color="--darkL2">
							UI design
						</Text>
						<Text margin="0px 0px 0px 0px" font="--base" color="--darkL2">
							Illustrations
						</Text>
						<Text margin="0px 0px 0px 0px" font="--base" color="--darkL2">
							Game design
						</Text>
						<Text margin="0px 0px 0px 0px" font="--base" color="--darkL2">
							Management
						</Text>
					</Box>
				</Box>
			</Section>
			<Section quarkly-title="Video" margin="0 0 12px 0" inner-width="100%" sm-padding="0px 0 0px 0">
				<Components.QuarklycommunityKitYouTube url="https://youtu.be/47kGGGJzxB0" width="100%">
					<Override slot="YouTube Button Overlay" border-width="1px" border-style="solid" border-color="--color-lightD2" />
				</Components.QuarklycommunityKitYouTube>
			</Section>
			<Section quarkly-title="Cover" margin="50px 0 0px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="center"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Box
					min-width="100px"
					min-height="100px"
					grid-column="2/3"
					display="flex"
					flex-direction="row"
					flex-wrap="wrap"
				>
					<Text
						margin="0px 0px 24px 0px"
						color="--darkL2"
						font="--headline2"
						width="100%"
						sm-font="--headline3"
					>
						BigMaconomics
					</Text>
					<Text
						margin="0px 0px 24px 0px"
						color="--darkL2"
						font="--lead28"
						max-width="760px"
						sm-font="--lead21"
						width="100%"
					>
						A humorous mini-app that allows you to calculate how many bigmacs you can afford on your salary. The data is taken from The Economist's Big Mac Index.
					</Text>
					<Text
						margin="0px 0px 24px 0px"
						color="--darkL2"
						font="--lead28"
						max-width="760px"
						sm-font="--lead21"
						width="100%"
					>
						The app is made on the{" "}
						<Link
							href="https:/quarkly.io/"
							color="--primary"
							target="_blank"
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							quarkly.io
						</Link>
						{" "}builder.
					</Text>
					<Box
						min-width="100px"
						min-height="100px"
						width="66%"
						padding="0px 16px 0px 0px"
						sm-width="100%"
					>
						<LinkBox align-items="flex-start" flex-direction="row" justify-content="flex-start" margin="0px 0px 6px 0px">
							<Image
								src="https://uploads.quarkly.io/5f841d553d84d4001ec38fac/images/favicon-32.png?v=2021-01-12T13:45:53.006Z"
								display="block"
								width="24px"
								height="24px"
								margin="0px 6px 0px 0px"
								srcSet="https://smartuploads.quarkly.io/5f841d553d84d4001ec38fac/images/favicon-32.png?v=2021-01-12T13%3A45%3A53.006Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/5f841d553d84d4001ec38fac/images/favicon-32.png?v=2021-01-12T13%3A45%3A53.006Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/5f841d553d84d4001ec38fac/images/favicon-32.png?v=2021-01-12T13%3A45%3A53.006Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/5f841d553d84d4001ec38fac/images/favicon-32.png?v=2021-01-12T13%3A45%3A53.006Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/5f841d553d84d4001ec38fac/images/favicon-32.png?v=2021-01-12T13%3A45%3A53.006Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/5f841d553d84d4001ec38fac/images/favicon-32.png?v=2021-01-12T13%3A45%3A53.006Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/5f841d553d84d4001ec38fac/images/favicon-32.png?v=2021-01-12T13%3A45%3A53.006Z&quality=85&w=3200 3200w"
								sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,(max-width: 1536px) 100vw,100vw"
							/>
							<Link
								href="https://bigmaconomics.com/"
								color="--primary"
								display="inline-block"
								target="_blank"
								font="--base"
								text-decoration-line="initial"
								hover-color="#0b85db"
							>
								bigmaconomics.com
							</Link>
						</LinkBox>
						<Text margin="0px 0px 0px 0px" font="--base" color="--darkL2">
							Completion Date: November 17, 2020
						</Text>
						<Text margin="0px 0px 0px 0px" font="--base" color="--darkL2">
							Made in 10 days
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px" width="33%" sm-width="100%">
						<Text margin="0px 0px 0px 0px" font="--base" color="--darkL2">
							UI design
						</Text>
						<Text margin="0px 0px 0px 0px" font="--base" color="--darkL2">
							Illustrations
						</Text>
						<Text margin="0px 0px 0px 0px" font="--base" color="--darkL2">
							Management
						</Text>
					</Box>
				</Box>
			</Section>
			<Section quarkly-title="Video" margin="0 0 12px 0" inner-width="100%" sm-padding="0px 0 0px 0">
				<Components.QuarklycommunityKitYouTube
					url="https://youtu.be/Us1uvbh4qYM"
					width="100%"
					border-width="1px"
					border-style="solid"
					border-color="--color-lightD2"
				/>
			</Section>
			<Components.PrevNextButtons>
				<Override slot="text">
					WebAsk
				</Override>
				<Override slot="linkBox" href="/works/webask" />
				<Override slot="text1">
					uKit
				</Override>
				<Override slot="linkBox1" href="/works/ukit" />
				<Override slot="quarklycommunityKitMenuOverride4">
					Mini projects
				</Override>
			</Components.PrevNextButtons>
			<Components.Footer>
				<Override slot="socialMedia" />
			</Components.Footer>
		</Box>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"622f01726ba4ec00186af2fd"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
			<script type={"text/javascript"} place={"endOfHead"} rawKey={"62b188e99e03ae0e7196a484"}>
				{"(function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};\n   m[i].l=1*new Date();k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})\n   (window, document, \"script\", \"https://mc.yandex.ru/metrika/tag.js\", \"ym\");\n\n   ym(31525948, \"init\", {\n        clickmap:true,\n        trackLinks:true,\n        accurateTrackBounce:true\n   });"}
			</script>
		</RawHtml>
	</Theme>;
});